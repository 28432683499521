import React from 'react';
import MyAppNav from './Nav';
 
 
function About() {
 
    return(
        <div>
        <MyAppNav/>
 
        <h1>A propos de nous</h1>
        </div>
    )
}

export default About;