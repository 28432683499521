import React from 'react';
import NavForm from './NavForm';
 
function MentionsLegales() {
 
    return(
        <div>
        <NavForm/>
        <h1>Mentions Légales</h1>
 
        
        </div>
    )
}

export default MentionsLegales;