import React from 'react';
import MyAppNav from './Nav';
 
 
function Forum() {
 
    return(
        <div>
        <MyAppNav/>
 
        <h1>Le Forum</h1>
        </div>
    )
}

export default Forum;