import React from 'react';
import MyAppNav from './Nav';
import TraderProduct from './TraderProduct';
 
 
function ProfilTrader() {
 
    return(
        <div>
        <MyAppNav/>
        
 
        

        <TraderProduct/>
        
        </div>
    )
}

export default ProfilTrader;